<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select v-model="searchData.searchType"
                       placeholder="请选择"
                       style="width: 120px">
              <el-option
                v-for="item in searchKeyType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input class=""
                      placeholder="搜索关键字"
                      prefix-icon="el-icon-search"
                      v-model="searchData.searchValue"
                      @keyup.enter.native="search"
                      style="width: 260px">
              <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button class="" type="primary" icon="el-icon-plus"  @click="showAddNew">新增</el-button>
          </el-form-item>
        </el-form>
      </template>

      <template v-slot:table="row">
        <el-table
          :data="tableData"
          :height="row.heightText"
          v-if="row.height">
          <el-table-column
            prop="name"
            label="模板名称"
            width="260">
          </el-table-column>
          <el-table-column
            prop="isFree"
            label="是否包邮"
            width="160">
            <template slot-scope="scope">
              {{isPostageFree[scope.row.isFree]}}
            </template>
          </el-table-column>
          <el-table-column
            prop="defaultFeeText"
            label="默认规则"
            width="300">
          </el-table-column>
          <el-table-column
            prop="updateTime_text"
            label="更新时间"
            width="180">
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-view" @click="getPostageInfo(scope.row)">查看</el-button>
              <el-button type="danger" size="mini" icon="el-icon-delete" @click="deletePostageItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </table-height-auto>

    <el-drawer
      class="drawer-scroll-y"
      title="运费模板"
      ref="drawerNameEditGoods"
      :withHeader="true"
      :visible.sync="visibleAddNew"
      :append-to-body="true"
      @closed="hideAddNew"
      size="calc(100% - 300px)">
      <div class="drawer-scroll-y-box">
        <edit-postage :data="editGoodsData" :table-data="tableData" :type="editGoodsType" @success="editGoodsSuccess"></edit-postage>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  import {baseURL,URL,URL_MINOR} from '../../../config'
  import {isEmpty,objectRemoveEmpty,disposeSearchData,parseTimeObject} from '../../../assets/js/utils'
  import editPostage from '../../../components/supplier/editPostage/editPostage'
  import {isPostageFree} from '../../../assets/js/status'

  export default {
    name: 'supplierPostage',
    components: {
      editPostage,
    },
    data(){
      return {
        loading: false,
        /* 数据表格 */
        isPostageFree,
        searchKeyType:[
          {id: 'name', name: '模板名称',disabled:true},
        ],
        classifyList:[],
        classifyValue:[''],
        searchData:{
          searchType:'name',
          searchValue:'',
        },
        tableData: [], // 数据列表
        /* 新增属性组弹窗 */
        visibleAddNew:false,
        editGoodsData:{},
        editGoodsType:1,
      }
    },
    created(){
      this.getList()
    },
    methods:{
      /**
       * 拼接搜索参数
       */
      jointSearchData(type){
        let data = {
        };
        data = Object.assign(data,this.searchData);
        disposeSearchData(data)
        return data;
      },
      /**
       * 搜索
       */
      search(type){
        this.currentPage = 1;
        this.getList()
      },
      /**
       * 查询列表数据
       */
      getList(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.supplierPostage.list,{params:data}).then(res=>{
            res.data.forEach(res=>{
              parseTimeObject(res)
            })
            this.tableData = res.data;
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
            // reject(res);
          }).finally(res=>{
            this.loading = false;
          })
        })
      },
      /**
       * 显示 编辑属性组弹窗
       * @param data  {object}  //编辑时需要传入编辑的数据对象
       * @param type  {number}  //编辑类型
       * */
      showAddNew(data,type = 1){
        if(typeof data === 'object' && type === 0){
          this.editGoodsData = data
        }else{
          this.editGoodsData = {}
        }
        this.visibleAddNew = true
      },
      hideAddNew(){
        this.visibleAddNew = false;
      },
      editGoodsSuccess(res){
        this.getList()
        this.hideAddNew()
      },
      /**
       * 获取运费模板详情
       */
      getPostageInfo(row){
        let data = {
          id:row.id,
        }
        this.loading = true;
        this.axios.get(URL.supplierPostage.details,{params:data}).then(res=>{
          this.showAddNew(res.data,0)
        }).catch(res=>{

        }).finally(res=>{
          this.loading = false;
        })
      },
      /**
       * 删除运费模板
       */
      deletePostageItem(row){
        let data = {
          id:row.id,
        }
        this.$confirm('删除后使用该模板的商品将会【包邮】, 是否继续?','确认删除',{
          confirmButtonText: '删除',
          type: 'error'
        }).then(res=>{
          this.loading = true;
          return this.axios.post(URL.supplierPostage.deleteSupplierPostage,this.qs.stringify(data))
        }).then(res=>{
          this.getList()
        }).catch(res=>{

        }).finally(res=>{
          this.loading = false;
        })
      },
    },
    watch:{

    }
  }
</script>

<style lang="less">
  .form-item{
    .el-input{
      max-width: 300px;
    }
  }
</style>
<style lang="less" scoped>
  .goods-cover{
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
</style>
